<template>
  <div class="bg-dark">
    <Header />
    <Contents />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import "@/assets/css/style_form_page.css";
import Header from "@/components/formHeader.vue";
import Contents from "@/components/Login.vue";
import Footer from "@/components/formFooter.vue";

export default {
  name: "Login",
  components: {
    Header,
    Contents,
    Footer,
  },
  mounted: function () {
    const title = "ログイン | MOG(モグー) ボートレース蒲郡";
    const description =
      "ボートレース蒲郡が主催するボートレースの新しい楽しみ方。エースモーターを選び、モグーを貯めて賞品をゲットしよう！";
    document.title = title;
    // document.querySelector("meta[property='og:url']").setAttribute('content', this.$route.fullpath)
    document
      .querySelector("meta[property='og:title']")
      .setAttribute("content", title);
    document
      .querySelector("meta[name='description']")
      .setAttribute("content", description);
    document
      .querySelector("meta[property='og:description']")
      .setAttribute("content", description);
  },
};
</script>
