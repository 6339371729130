<template>
  <footer class="bg-dark">
    <p class="copyright">&copy; BOAT RACE GAMAGORI All rights reserved.</p>
  </footer>
</template>

<script>
export default {
  name: "formFooter",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_form_page.css" scoped></style>
