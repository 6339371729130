<template>
  <header class="header_login">
    <h1>
      <router-link to="/">
        <img
          src="../assets/images/common/footer_logo.svg"
          alt="MOG Motor Owner GrandPrix"
        />
      </router-link>
    </h1>
  </header>
</template>

<script>
export default {
  name: "formHeader",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_form_page.css" scoped></style>
