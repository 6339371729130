<template>
  <div class="content03">
    <h2>ログイン</h2>
    <div class="wrap">
      <form action="">
        <dl>
          <dt>メールアドレス</dt>
          <dd>
            <input
              type="text"
              class="account"
              id="account"
              v-model="user.mail"
            /><span class="link_txt"
              ><router-link to="/remind_mail" class="txt-link"
                >メールアドレスをお忘れの方はコチラ</router-link
              ></span
            >
          </dd>
        </dl>
        <dl>
          <dt>パスワード</dt>
          <dd>
            <input
              type="password"
              class="pass"
              id="pass"
              v-model="user.pass"
            /><span class="link_txt"
              ><router-link to="/remind_pass" class="txt-link"
                >パスワードをお忘れの方はコチラ</router-link
              ></span
            >
          </dd>
        </dl>
        <p class="submit">
          <input type="submit" value="ログイン" v-on:click.prevent="login()" />
        </p>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      user: {},
    };
  },
  methods: {
    login() {
      this.$store.dispatch("login", this.user).then((response) => {
        if (response.data.result) {
          this.$router.push("/mypage");
        } else {
          alert("メールアドレスとパスワードが正しくありません。");
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_form_page.css" scoped></style>
<style lang="css" src="@/assets/css/style_form_parts.css" scoped></style>
